<template>
    <div>
        <main class="main" id="top">
            <section>
                <div class="container">
                    <div class="row">
                        <h1 class="text-center mb-5">All Projects</h1>
                        <div style="text-align:center" v-if="projects.length == 0">
                            <h3>No Projects Available</h3>
                        </div>
                        <div @click="displayImage(project.Projectimg)" class="col-md-4 mb-4"
                            v-for="(project, index) in projects" :key="index">
                            <div class="card h-100">
                                <img v-if="project.Projectimg" class="card-img-top w-100"
                                    :src="'/uploads/ekscda/' + project.Projectimg" alt="courses" />
                                <img v-else src="img/no-img.png" alt="">
                                <div class="card-body">
                                    <h5 class="font-sans-serif fw-bold fs-md-0 fs-lg-1">Community Name:
                                        {{ communityInfo(project.cdps_id) }}</h5><a
                                        class="text-muted fs--1 stretched-link text-decoration-none" href="#!">Sector:
                                        {{
                                                project.Sector
                                        }}</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <!-- end of .container-->

            </section>

        </main>


        <v-dialog v-model="dialog" persistent max-width="900px" max-height="900px">
            <v-card class="display-img">
                <img v-if="image" class="card-img-top w-100" :src="'/uploads/ekscda/' + image"
                    alt="courses" />
                <img v-else src="img/no-img.png" alt="">
                <v-btn class="btn_image" @click="dialog = false">Close</v-btn>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { apiClient } from "@/services";
export default {
    name: "Projects",
    data: () => ({
        projects: [],
        community: [],
        dialog: false,
        image: "",
    }),
    created() {
        apiClient
            .get("/mps_info?u=2")
            .then((res) => {
                if (Array.isArray(res.data)) {
                    this.projects = res.data;

                } else {
                    this.displayMsg(res.data, "error");
                }
            })
            .catch((err) => this.displayMsg(err.message, "error"));


        apiClient
            .get("/community_info?u=2")
            .then((res) => {
                if (Array.isArray(res.data)) {
                    this.community = res.data;

                } else {
                    this.displayMsg(res.data, "error");
                }
            })
            .catch((err) => this.displayMsg(err.message, "error"));

    },

    methods: {
        displayImage(image) {
            this.dialog = true
            this.image = image

        },

        communityInfo(id) {
            let community = this.community.find((e) => {
                return e.cdps_id == id
            })
            return community.CommunityName
        }
    }

}
</script>

<style scoped>
.display-img {
    width: 90vw !important;
    height: 90vh !important;
    position: relative;
}

.display-img img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

}

.display-img .btn_img{
    z-index: 111;
}
</style>










<style scoped src="../assets/theme.css">